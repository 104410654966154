.Breadcrumbs, .item{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    font-size: 14px;
}


.item{
  width: fit-content;  
  color: var(--secondary-text);
}
.more {
    padding: 4px;
    border-radius: 2px;
    background-color: var(--social-background);
}

.Route{
    width: 100%;

}
.Route a{
    font-size: 12px;
    line-height: 140.62%;
    
}

@media (min-width: 700px) {
   .Route a{
    font-size: var(--font-size-xs);
   } 
}