@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
.root,
#root{
  --secondary-bg: #1C3C54;
  --primary-bg:#FFFFFF;
  --icon-bg:#997950;
  --backdrop-bg:rgba(0, 0, 0, 0.8);
  --primary-text:#181818;
  --secondary-text:#979797;
  --tertiary-text:#997950;
  --label-text:#666666;
  --placeholder-text:#0404044D;
  --action-text:#040404;
  --orange:#FFF7EB;
  --orange-accent: rgba(255, 157, 0, 0.2);
  --orange-dark:#FF9D00;
  --orange-light:#FF9D0066;
  --accent-border:#F7F7F7;
  --green:#EDFFF6;
  --green-accent:rgba(4, 136, 72, 0.2);
  --green-dark:#048848;
  --green-light:#04884866;
  --purple:#F7F5FF;
  --purple-accent:rgba(112, 91, 250, 0.2);
  --purple-dark:#705BFA;
  --purple-light:#705BFA66;
  --pink:#FFF3F1;
  --pink-accent: rgba(225, 25, 0, 0.2);
  --pink-dark:#E11900;
  --pink-light:#E1190066;
  --input-bg: #F7F7F7;


  --xlg: 38px;
  --lg: 22px;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: 'SF UI Display';
  font-weight: 900;
  src: local('SF UI Display'), url("./assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-black-58646a6b80d5a.otf") format('opentype');
}
@font-face {
  font-family: 'SF UI Display';
  font-weight: 800;
  src: local('SF UI Display'), url("./assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-heavy-586470160b9e5.otf") format('opentype');
}
@font-face {
  font-family: 'SF UI Display';
  font-weight: 700;
  src: local('SF UI Display'), url("./assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-bold-58646a511e3d9.otf") format('opentype');
}
@font-face {
  font-family: 'SF UI Display';
  font-weight: 600;
  src: local('SF UI Display'), url("./assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-semibold-58646eddcae92.otf") format('opentype');
}
@font-face {
  font-family: 'SF UI Display';
  font-weight: 500;
  src: local('SF UI Display'), url("./assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-medium-58646be638f96.otf") format('opentype');
}
@font-face {
  font-family: 'SF UI Display';
  font-weight: 300;
  src: local('SF UI Display'), url("./assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-light-58646b33e0551.otf") format('opentype');
}
@font-face {
  font-family: 'SF UI Display';
  font-weight: 200;
  src: local('SF UI Display'), url("./assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-thin-58646e9b26e8b.otf") format('opentype');
}
@font-face {
  font-family: 'SF UI Display';
  font-weight: 100;
  src: local('SF UI Display'), url("./assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-ultralight-58646b19bf205.otf") format('opentype');
}
body {
  margin: 0;
  font-family:'SF UI Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: var(--primary-text);
  line-height: 1.2;
  font-weight: 500;
  font-size: var(--xlg);
}

h1 + p.sub-heading {
  font-weight: 500;
  font-size: var(--lg);
  line-height: 1.4;
  color: var(--secondary-text)
}

input:not(:checked) + div{
  @apply bg-transparent;
}
input:checked+div {
  /* @apply outline outline-1 ring ring-offset-point-tertiary-bg outline-offset-0 ; */

}

input:checked+div svg {
  @apply block;
}


.scroller {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #001540;
  
}

.scroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;

}

.scroller::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
  /* display: none; */
}

.scroller::-webkit-scrollbar-thumb {
  color: #001540;
  border-radius: 20px;
  background: #001540;
  z-index: 1000;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #001540;
  border-color: #3f3d56;
  border-radius: 20px;
}